import moment from 'moment'

const timeWithTimeZone = (date, timezone, format = 'YYYY-MM-DD HH:mm:ss') => {
  if (!date) return '-'
  if (moment(date, 'HH:mm:ss').isSame(moment(date, 'HH:mm:ss').startOf('day'))) return moment(date).format(format)
  const cutoffString = date // in utc
  const utcCutoff = moment.utc(cutoffString, 'YYYYMMDD HH:mm:ss')
  const displayCutoff = utcCutoff.clone().tz(timezone)
  return displayCutoff.format(format)
}

const workPatternHelper = (busy, { start, end }, timezone) => {
  const startTime = start
  const endTime = end
  const manualTime = busy
    .filter(b => b.isManual)
    .map(b => ({
      start: new Date(b.start).getTime(),
      end: new Date(b.end).getTime(),
      uuid: b.uuid,
    }))
    /* eslint-disable */
    function normalize(slots, startTime, endTime) {
      startTime = new Date(startTime);
      endTime = new Date(endTime);
      return slots.map((item) => {
        item.start = new Date(item.start);
        item.end = new Date(item.end);
        if (item.start < startTime) item.start = startTime;
        if (item.end > endTime) item.end = endTime;
        return item;
      });
    }

    let dataSlots = normalize(busy, startTime, endTime);
    const startTimeStamp = new Date(startTime).getTime();
    const endTimeStamp = new Date(endTime).getTime();
    dataSlots = dataSlots.reverse();
    const status = [];
    let slotItr = "";
    for (slotItr = startTimeStamp; slotItr <= endTimeStamp; ) {
      if (dataSlots.length === 0) {
        break;
      }
      const slot = dataSlots.pop();

      const slotStart = slot.start.getTime();
      const slotEnd = slot.end.getTime();
      if (slotItr === slotStart) {
        const isManual = manualTime.find((b) => b.start === slot.start.getTime() && b.end === slot.end.getTime());
        status.push({ ...slot, working: true, manual: isManual });
        slotItr = slotEnd;
      } else if (slotItr < slotStart) {
        status.push({
          start: new Date(slotItr),
          end: new Date(slotStart),
          working: false,
        });
        const isManual = manualTime.find(
          (b) =>
            b.start === slot.start.getTime() && b.end === slot.end.getTime()
        );
        status.push({ ...slot, working: true, manual: isManual });
        slotItr = slotEnd
      }
    }

    if (slotItr !== endTimeStamp) {
      // if there is timezone, get the time accordingly, else without the timezone
      status.push({
        start: timezone ? timeWithTimeZone(new Date(slotItr), timezone) : new Date(slotItr),
        end: timezone ? timeWithTimeZone(new Date(endTimeStamp), timezone) : new Date(endTimeStamp),
        working: false,
      });
    }
    return status;
}

export default workPatternHelper
<template>
  <div>
    <h4 class="mb-2">{{ $t('Recent Screenshots') }} <feather-icon
      v-b-tooltip.hover
      class="cursor-pointer"
      icon="InfoIcon"
      size="16"
      title="Recent screenshots from app"
      style="margin-bottom: 3px;"
    /></h4>
    <b-overlay
      :show="processing"
      rounded="sm"
    >
      <b-col
        v-if="screenCasts.length === 0"
        cols="12"
        class="mt-3 mb-3"
      >
        <b-skeleton-img
          v-if="processing"
          no-aspect
          height="250px"
        />
        <b-card
          v-else
          class="text-center"
        >
          <h1>
            😕 {{ $t('No Data Found') }}
          </h1>
        </b-card>
      </b-col>
      <b-row>
        <b-col style="padding-right: 2rem">
          <app-timeline>
            <app-timeline-item
              v-for="(screens,date, loopIndex) in groupedScreenshots"
              :key="date"
              :variant="loopIndex % 2 === 0 ? 'primary' : 'success'"
            >
              <h6>
                {{ FORMAT_DATE(date,'MMM Do YYYY') }}
              </h6>
              <b-row>
                <b-col
                  v-for="(value,index) in screens"
                  :key="index"
                  sm="2"
                  class="pr-0"
                >
                  <card-image
                    v-slot="{ totalMonitor }"
                    :src="value.record.title"
                    :height="200"
                    @showPreview="showImages"
                  >
                    <template>
                      <h6>
                        <div class="d-flex justify-content-between">
                          <div>
                            <feather-icon
                              icon="ClockIcon"
                              size="13"
                            />
                            {{ timeWithOutTimeZone(value.endedAt, 'hh:mm A') }}
                          </div>
                          <div>
                            <feather-icon
                              icon="AirplayIcon"
                              size="13"
                            />
                            {{ totalMonitor }}
                          </div>
                        </div>
                      </h6><hr>
                      <div class="row">
                        <div class="col-6 text-truncate">
                          <b-progress
                            :value="value.keyClicks"
                            max="100"
                            style="height: 8px"
                            variant="success"
                            class="progress-bar-success"
                          />
                          <span
                            class="text-nowrap"
                            title="Key Clicks"
                          >
                            <feather-icon icon="TypeIcon" /><span class="iotype"> Key Clicks</span>
                          </span>
                        </div>
                        <div class="col-6 text-truncate">
                          <b-progress
                            :value="value.mouseMoves"
                            max="100"
                            variant="success"
                            style="height: 8px"
                            class="progress-bar-success"
                          />
                          <span
                            class="text-nowrap"
                            title="Mouse Moves"
                          >
                            <feather-icon
                              icon="MousePointerIcon"
                            />
                            <span class="iotype">Mouse Moves</span>
                          </span>
                        </div>
                      </div>
                    </template>
                    <!----><!---->
                  </card-image>
                </b-col>
              </b-row>
            </app-timeline-item>
          </app-timeline>
        </b-col>
      </b-row>
    </b-overlay>
    <portal to="lightbox">
      <CoolLightBox
        :items="sources"
        :index="slide"
        :enable-scroll-lock="false"
        @close="slide = null"
      />
    </portal>
  </div>
</template>
<script>
import {
  BProgress, BOverlay, BSkeletonImg, BCard,
} from 'bootstrap-vue'
import CardImage from '@/views/common/components/CardImage.vue'
import CoolLightBox from 'vue-cool-lightbox'
import AppTimeline from '@core/components/app-timeline/AppTimeline.vue'
import AppTimelineItem from '@core/components/app-timeline/AppTimelineItem.vue'

export default {
  components: {
    BProgress,
    CardImage,
    BOverlay,
    BSkeletonImg,
    CoolLightBox,
    BCard,
    AppTimeline,
    AppTimelineItem,
  },
  props: {
    processing: {
      type: Boolean,
      default: false,
    },
    screenCasts: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      toggler: false,
      slide: null,
      sources: [],
    }
  },
  computed: {
    groupedScreenshots() {
      const screenCasts = this.screenCasts.map(screen => ({
        ...screen,
        timeKey: this.FORMAT_DATE(screen.endedAt, this.groupKey),
      }))
      return this.GROUP_BY(screenCasts, 'timeKey')
    },
    groupKey() {
      return 'YYYY-MM-DD'
    },
  },
  methods: {
    showImages(images) {
      this.toggler = !this.toggler
      const imagesArray = []
      this.screenCasts.forEach(screen => {
        const sources = images ? screen.record.title.split(',') : []
        imagesArray.push(...sources)
      })
      const viewingImage = images
      this.slide = imagesArray.findIndex(img => img === viewingImage)
      this.sources = imagesArray
    },
  },
}
</script>
<style>
 .iotype {
   font-size: 12px;
 }
</style>

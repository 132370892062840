<template>
  <div>
    <work-summary
      :work-summary="workSummary"
      :processing="processing"
    />
    <b-card body-class="p-0">
      <table class="table table-striped table-hover table-bordered">
        <thead>
          <tr>
            <th class="bg-primary text-white">
              Date
            </th>
            <th class="bg-primary text-white">
              Time Tracked
            </th>
            <th class="text-nowrap bg-primary text-white">
              Start Time
            </th>
            <th class="text-nowrap bg-primary text-white">
              End Time
            </th>
            <th class="text-nowrap bg-primary text-white">
              <div class="d-flex">
                <div
                  v-for="hour of timeScale"
                  :key="hour"
                  class="hours-bars"
                >
                  <span
                    v-if="hour > 11 && hour < 23 && hour % 2 == 0"
                    class="time-indicator  text-nowrap"
                    style="margin-top: 0"
                  >
                    {{ hour - 12 == 0 ? '12' : hour - 12 }} PM
                  </span>
                  <span
                    v-else-if="hour > 1 && hour < 12 && hour % 2 == 0 "
                    class="time-indicator  text-nowrap text-white"
                    style="margin-top: 0"
                  >
                    {{ hour }} AM
                  </span>
                </div>
              </div>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="day of daysRangeForWorkPattern"
            :key="day.date"
            @click="activeDate = day.date"
          >
            <td class="col-1 text-nowrap">
              <b>{{ FORMAT_DATE(day.date, 'D MMM YYYY, ddd') }}</b>
            </td>
            <td class="col-1 text-nowrap">
              <b>
                <span v-if="patternData[day.date]">
                  {{ CONVERT_HM(patternData[day.date].totalWorkingTime) }}
                </span>
                <span v-else>
                  0m
                </span>
              </b>
            </td>
            <td class="col-1 text-nowrap">
              <b>
                <span v-if="patternData[day.date]">
                  {{ patternData[day.date].startEndTime.start }}
                </span>
                <span v-else>
                  -
                </span>
              </b>
            </td>
            <td class="col-1 text-nowrap">
              <b>
                <span v-if="patternData[day.date]">
                  {{ patternData[day.date].startEndTime.end }}
                </span>
                <span v-else>
                  -
                </span>
              </b>
            </td>
            <td>
              <gantt-chart
                :start-end-date-time="day"
                :hide-label="true"
                :work-patterns="getFilteredWorkPattern(day.date)"
                @shareDataToParent="(data) => receiveDataFromGanttChart(day.date, data)"
              />
            </td>
          </tr>
        </tbody>
      </table>
    </b-card>
    <b-sidebar
      :visible="!! singleDate"
      bg-variant="white"
      right
      width="80vw"
      backdrop
      shadow
      @hidden="activeDate = null"
    >
      <div
        v-if="singleDate"
        class="p-2"
      >
        <day-view
          :date-range="singleDate"
          :user-id="userId"
        />
      </div>
    </b-sidebar>
  </div>
</template>
<script>
import useApollo from '@/plugins/graphql/useApollo'
import GanttChart from '@/views/common/components/GanttChartTime.vue'
import { BCard, BSidebar } from 'bootstrap-vue'
import WorkSummary from '../SingleDay/WorkSummary.vue'
import DayView from '../SingleDay/Index.vue'

export default {
  components: {
    GanttChart,
    BCard,
    WorkSummary,
    BSidebar,
    DayView,
  },
  props: {
    dateRange: {
      type: Object,
      required: true,
    },
    userId: {
      type: [Object, String],
      default: '',
    },
    timeZone: {
      type: [String],
      default: '',
    },
  },
  data() {
    return {
      daysRangeForWorkPattern: [],
      workPatterns: [],
      timeScale: Array.from(Array(24).keys()),
      patternData: {},
      processing: false,
      workSummary: {
        totalDuration: 0,
        productiveDuration: 0,
        unproductiveDuration: 0,
        neutralDuration: 0,
        manualDuration: 0,
      },
      activeDate: null,
    }
  },
  computed: {
    singleDate() {
      if (this.activeDate) {
        return {
          start: `${this.activeDate} 00:00:00`,
          end: this.ADD_DAYS(this.activeDate, 1, 'YYYY-MM-DD 00:00:00').start,
        }
      }
      return null
    },
  },
  watch: {
    dateRange() {
      this.getUserActivities()
    },
    userId() {
      this.getUserActivities()
    },
    timeZone() {
      this.getUserActivities()
    },
  },
  created() {
    this.getUserActivities()
  },
  methods: {
    getUserActivities(page = 1) {
      this.processing = true
      useApollo.activities
        .getUserActivities({
          startTime: this.dateRange.start,
          endTime: this.dateRange.end,
          keyword: this.$store.state.project.selectedProject,
          userId: this.userId,
          first: 20,
          page,
        }).then(response => {
          const screenCastsData = response.data.user
            ? response.data.user
            : response.data.me
          this.workSummary = {
            totalDuration: screenCastsData.recordsDuration,
            productiveDuration: screenCastsData.productiveDuration,
            unproductiveDuration: screenCastsData.unproductiveDuration,
            neutralDuration: screenCastsData.neutralDuration,
            manualDuration: screenCastsData.manualDuration,
          }
        }).catch(err => {
          this.showErrorMessage(err)
        })
        .finally(() => {
          this.processing = false
        })
      this.getDailyActivity()
    },
    getDailyActivity() {
      useApollo.activities.getWorkPattern({
        startTime: this.dateRange.start,
        endTime: this.dateRange.end,
        projectId: this.$store.state.project.selectedProject,
        userId: this.userId,
      }).then(response => {
        const activitiesData=response.data.me.activities.data;
        activitiesData.sort((a,b)=>new Date(a.startedAt)-new Date(b.startedAt));
        this.workPatterns = activitiesData.map(ranges => ({
          ...ranges,
          day: this.FORMAT_DATE(ranges.startedAt, 'YYYY-MM-DD'),
          endDay: this.FORMAT_DATE(ranges.endedAt, 'YYYY-MM-DD'),
        }))
        const daysBetween = this.GET_DAY_BETWEEN_DAYS(this.dateRange.start, this.dateRange.end)
        this.daysRangeForWorkPattern = daysBetween.reverse()
      })
    },
    receiveDataFromGanttChart(day, data) {
      this.patternData[day] = data
      // if (!this.patternData[day]) {
      //   this.patternData = {
      //     ...this.patternData,
      //     [day]: data,
      //   }
      // }
    },
    getFilteredWorkPattern(date) {
      return this.workPatterns.filter(work => work.day === date || work.endDay === date)
    },
  },
}
</script>

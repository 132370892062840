<template>
  <b-overlay
    id="tour-work-summary"
    :show="processing"
  >
    <b-card>
      <b-row>
        <b-col>
          <span>{{ $t('Total time worked') }}</span>
          <h3>
            {{ CONVERT_HM(workSummary.totalDuration) }}
          </h3>
          <p v-if="thisWeekDuration || thisMonthDuration">
            <span>This week:</span>
            <span class="ml-2">{{ CONVERT_HM(thisWeekDuration) }}</span><br>
            <span>This month:</span>
            <span class="ml-2">{{ CONVERT_HM(thisMonthDuration) }}</span><br>
          </p>
        </b-col>
        <b-col>
          <span>{{ $t('Productive time') }} </span>
          <h3>{{ CONVERT_HM(workSummary.productiveDuration) }}</h3>
          <br>
          <p>
            <b-progress
              :value="CALC_PERCENTAGE({
                total: workSummary.totalDuration,
                value: workSummary.productiveDuration
              })"
              max="100"
              variant="success"
              class="progress-bar-success"
            />
          </p>
        </b-col>
        <b-col>
          <span>{{ $t('Unproductive time') }}
            <feather-icon
              v-b-tooltip.hover="'Time spent on unproductive applications'"
              icon="InfoIcon"
            />
          </span>
          <h3>{{ CONVERT_HM(workSummary.unproductiveDuration) }}</h3>
          <br>
          <p>
            <b-progress
              :value="CALC_PERCENTAGE({
                total: workSummary.totalDuration,
                value: workSummary.unproductiveDuration
              })"
              max="100"
              variant="danger"
              class="progress-bar-danger"
            />
          </p>
        </b-col>
        <b-col>
          <span>{{ $t('Neutral time') }}
            <feather-icon
              v-b-tooltip.hover="'Time spent on neutral applications'"
              icon="InfoIcon"
            />
          </span>
          <h3>{{ CONVERT_HM(workSummary.neutralDuration) }}</h3>
          <br>
          <p>
            <b-progress
              :value="CALC_PERCENTAGE({
                total: workSummary.totalDuration,
                value: workSummary.neutralDuration
              })"
              max="100"
              variant="secondary"
              class="progress-bar-secondary"
            />
          </p>
        </b-col>
        <b-col>
          <span>{{ $t('Manual Time') }} </span>
          <h3>{{ CONVERT_HM(workSummary.manualDuration) }}</h3>
          <br>
          <p>
            <b-progress
              value="0"
              max="100"
              variant="primary"
              class="progress-bar-primary"
            />
          </p>
        </b-col>
      </b-row>
    </b-card>
  </b-overlay>
</template>
<script>
import {
  BCard, BProgress, BOverlay, VBTooltip,
}
from 'bootstrap-vue'

export default {
  components: {
    BCard,
    BProgress,
    BOverlay,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  props: {
    workSummary: {
      type: Object,
      default: () => {},
    },
    thisWeekDuration: {
      type: Number,
      default: 0,
    },
    thisMonthDuration: {
      type: Number,
      default: 0,
    },
    processing: {
      type: Boolean,
      default: false,
    },
  },
}
</script>

<template>
  <b-modal
    id="general-information-popup"
    ok-title="Configure Profile Information"
    cancel-title="Later"
    cancel-variant="danger"
    no-close-on-backdrop
    @ok="$router.push(getAlerts.path)"
    @cancel="disableGeneralInfoCheck"
  >
    <template #modal-title>
      <feather-icon
        icon="InfoIcon"
        size="24"
      />
      Configure Profile Information
    </template>

    <b-alert
      show
      variant="warning"
      class="p-1"
    >
      <div class="row">
        <div class="col-2 pr-0">
          <lottie-animation
            :animation-data="require('@/assets/animations/Exclamation.json')"
            :loop="true"
            :auto-play="true"
          />
        </div>
        <div class="col-10 pl-0">
          <p>
            We noticed that you haven't completed your profile information, which is crucial for processing your banking withdrawal. So, please configure it as soon as possible. Thank you!
          </p>
        </div>
      </div>
    </b-alert>
  </b-modal>
</template>

<script>
import { BModal, BAlert } from 'bootstrap-vue'
import { mapGetters } from 'vuex'
import LottieAnimation from 'lottie-web-vue'
import 'swiper/css/swiper.css'
import moment from 'moment'

export default {
  components: {
    BModal,
    BAlert,
    LottieAnimation,
  },
  data() {
    return {
      alerts: {
        general: {
          path: 'general-settings',
          text: this.$t('alerts.configure-general-info'),
        },
      },
      popupChecks: JSON.parse(localStorage.getItem('checkPopups')) ?? {},
    }
  },
  computed: {
    ...mapGetters('project', ['GET_INFORMATION_STATUS']),
    getAlerts() {
      if (!this.GET_INFORMATION_STATUS.general) {
        return this.alerts.general
      }

      return null
    },
  },
  watch: {
    'GET_INFORMATION_STATUS.general': {
      handler(val) {
        if (!val) {
          if (!this.popupChecks?.generalInfo || (this.popupChecks.generalInfo && !this.isAfterTODAY(this.popupChecks.generalInfo.expiresAt))) this.$bvModal.show('general-information-popup')
        }
      },
    },
  },
  mounted() {
    if (!this.GET_INFORMATION_STATUS.general) {
      if (!this.popupChecks?.generalInfo || (this.popupChecks.generalInfo && !this.isAfterTODAY(this.popupChecks.generalInfo.expiresAt))) this.$bvModal.show('general-information-popup')
    }
  },
  methods: {
    disableGeneralInfoCheck() {
      const generalInfo = {
        disableGeneralInfoCheck: true,
        expiresAt: moment().add(3, 'days').format('YYYY-MM-DD'),
      }

      const newPopupChecks = {
        ...this.popupChecks,
        generalInfo,
      }
      localStorage.setItem('checkPopups', JSON.stringify(newPopupChecks))
    },
  },
}
</script>

<style scoped>
.swiper-top-position {
  top: -60px;
}
.w-40 {
  width: 40%;
}
.navigators {
  margin-bottom: 4px;
}
</style>

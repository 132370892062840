<template>
  <div class="position-relative">
    <div
      v-if="!hideLabel"
      class="d-flex justify-content-between pb-2"
    >
      <div>
        <h4><feather-icon icon="CommandIcon" /> {{ $t('Work Pattern') }} ({{ FORMAT_DATE(startEndDateTime.start,'MMM D, YYYY') }})</h4>
      </div>
      <div v-if="totalWorkingTime ">
        <feather-icon icon="ClockIcon" /><span class="text-muted"> {{ $t('Total Worked') }}:</span> <b>{{ CONVERT_HM(totalWorkingTime) }}</b>
      </div>
      <div v-if="startEndTime">
        <span>
          <feather-icon
            icon="PlayCircleIcon"
            class="text-muted"
          /> <span class="text-muted">{{ $t('Start Time') }}:</span> <b>{{ startEndTime.start }}</b>
        </span>
        <span class="ml-2">
          <feather-icon
            icon="StopCircleIcon"
            class="text-muted"
          /> <span class="text-muted">{{ $t('End Time') }}:</span> <b>{{ startEndTime.end }}</b>
        </span>
      </div>
    </div>
    <div
      style="height: 50px;"
      class="d-flex mb-2"
    >
      <template v-if="!hideLabel">
        <div
          v-for="hour of timeScale"
          :key="hour"
          class="hours-bars"
        >
          <span
            v-if="hour > 11 && hour < 23 && hour % 2 == 0"
            class="time-indicator text-muted text-nowrap"
          >
            {{ hour - 12 == 0 ? '12' : hour - 12 }} PM
          </span>
          <span
            v-else-if="hour > 1 && hour < 12 && hour % 2 == 0"
            class="time-indicator text-muted text-nowrap"
          >
            {{ hour }} AM
          </span>
        </div>
      </template>
      <div
        v-for="(ft, index) of framesTime"
        :key="index + 'frame'"
        v-b-tooltip="{
          disabled: false,
        }"
        class="time-block"
        :title="`${workType(ft.worked)} ${CONVERT_HM(ft.time)}`"
        :style="{ width: ft.width + '%' , left: ft.left + '%', zIndex: 1}"
        :class="workColor(ft.worked)"
      />
      <template v-for="(ft, index) of deletedWorkingPattern.finalFrame">
        <div
          v-if="ft.worked"
          :key="index + 'frame_deleted'"
          v-b-tooltip="{
            disabled: false,
          }"
          :title="`Deleted ${CONVERT_HM(ft.time)}`"
          :style="{ width: ft.width + '%' , left: ft.left + '%', zIndex: 1}"
          class="time-block time-minutes-active bg-gradient-danger"
        />
      </template>

    </div>
  </div>
</template>
<script>
import { VBTooltip } from 'bootstrap-vue'
import workPatternHelper from '@/utils/workPatternHelper'

export default {
  directives: {
    'b-tooltip': VBTooltip,
  },
  props: {
    workPatterns: {
      type: Array,
      default: () => [],
    },
    startEndDateTime: {
      type: Object,
      required: true,
    },
    hideLabel: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      timeScale: Array.from(Array(24).keys()),
      framesTime: [],
      totalWorkingTime: 0,
      deletedWorkingPattern: {
        finalFrame: [],
        totalWorkingTime: 0,
      },
    }
  },
  computed: {
    timeFrame() {
      const frames = []
      this.workPatterns.forEach(pattern => {
        frames.push({
          start: this.timeWithOutTimeZone(pattern.startedAt, 'YYYY-MM-DD HH:mm:ss'),
          end: this.timeWithOutTimeZone(pattern.endedAt, 'YYYY-MM-DD HH:mm:ss'),
          isManual: !!pattern.manualDuration,
          deletedTimings: pattern.deletedTimings.data,
        })
      })
      return frames
    },
    startEndTime() {
      const frameTimes = [...this.framesTime]
      const startTime = frameTimes.find(ft => ft.worked)?.start
      if (!startTime) {
        return null
      }
      const endTime = frameTimes.reverse().find(ft => ft.worked)?.end
      return {
        start: this.FORMAT_DATE(startTime, 'hh:mm A'),
        end: this.FORMAT_DATE(endTime, 'hh:mm A'),
      }
    },
  },
  watch: {
    workPatterns: {
      handler(newValue) {
        if (newValue.length > 0) {
          this.getTimeFrame()
        } else {
          this.resetAllFrames()
        }
      },
      immediate: true,
      deep: true,
    },
  },
  methods: {
    pad2(number) {
      return (number < 10 ? '0' : '') + number
    },
    getDeletedFrame() {
      const timeZone = this.$store.getters['project/FILTERED_TIMEZONE_OR_DEFAULT']?.value
      const deletedTimeFrame = []
      this.timeFrame.forEach(frame => {
        frame.deletedTimings.forEach(deletedFrame => {
          deletedTimeFrame.push({
            start: new Date(this.timeWithTimeZone(deletedFrame.startedAt, timeZone)),
            end: new Date(this.timeWithTimeZone(deletedFrame.endedAt, timeZone)),
            isManual: false,
          })
        })
      })
      deletedTimeFrame.sort((a, b) => (+a.start) - (+b.start))
      return deletedTimeFrame
    },
    makeWorkPattern(timeFrame) {
      const finalFrame = []
      const totalSeconds = 86900
      let totalWorkingTime = 0
      timeFrame.forEach((pattern, index) => {
        const difference = this.GET_TIME_DIFFERENCE(pattern.start, pattern.end)
        if (difference >= 0) {
          const width = (difference / totalSeconds) * 100
          let left = 0
          if (finalFrame[index - 1]) {
            left = finalFrame[index - 1].left + finalFrame[index - 1].width
          }
          finalFrame.push({
            worked: pattern.manual ? 'manual' : pattern.working,
            start: pattern.start,
            end: pattern.end,
            width,
            left,
            time: difference,
          })
          if (pattern.manual || pattern.working) {
            totalWorkingTime += difference
          }
        }
      })

      return {
        totalWorkingTime,
        finalFrame,
      }
    },
    resetAllFrames() {
      this.deletedWorkingPattern = {
        finalFrame: [],
        totalWorkingTime: 0,
      }
      this.framesTime = []
    },
    getTimeFrame() {
      if (this.timeFrame.length === 0) {
        this.resetAllFrames()
        return
      }
      const normalWorkedPattern = this.makeWorkPattern(workPatternHelper(this.timeFrame, this.startEndDateTime))
      const deletedWorkedPattern = this.makeWorkPattern(workPatternHelper(this.getDeletedFrame(), this.startEndDateTime))
      this.framesTime = normalWorkedPattern.finalFrame
      this.totalWorkingTime = normalWorkedPattern.totalWorkingTime - deletedWorkedPattern.totalWorkingTime
      this.deletedWorkingPattern = deletedWorkedPattern
      this.$emit('shareDataToParent', {
        totalWorkingTime: this.totalWorkingTime,
        startEndTime: this.startEndTime,
      })
    },
    workColor(type) {
      if (type === 'manual') {
        return 'bg-warning time-minutes-inactive'
      }
      return type ? 'time-minutes-active bg-gradient-success' : 'time-minutes-inactive'
    },
    workType(type) {
      if (type === 'manual') {
        return 'Manual'
      }
      return type ? 'Working' : 'Not Working'
    },
  },
}
</script>
<style>
.timeFrame {
  width: 4.146%;
  height: 2em;
  margin-bottom: 1%; /* (100-32*3)/2 */
  position: relative;
  display: flex;
  height: 30px;
}
.time-minutes-inactive {
    height: 100%;
    position: relative;
    cursor: pointer;
}
.time-minutes-active {
    height: 100%;
    cursor: pointer;
    /* background:#4CAF4F; */
}
.time-indicator {
    position:absolute;
    margin-top: 55px;
    left: 0;
    right: 0;
    text-align: center;
}
.time-minutes-inactive:hover {
  border: 1px solid #af9e9e;
}
.hours-bars {
  position: relative; width: 4.5%;border-right: 1px solid #80808033;
}

.time-block {
  position: absolute;
  margin-top: 10px;
  height: 30px;
}

</style>
